import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import SwitchBlog from "../../templates/SwitchBlog";
import { useSearchFun } from "../SearchFunction/SearchFunction";
const Heading = () => {
  const [numberOfItems, setNumberOfItems] = useState(10);

  const data = useStaticQuery(graphql`
    {
      allStrapiPost(sort: { order: DESC, fields: date }) {
        nodes {
          slug
          date
          Title
          id
          description {
            data {
              description
            }
          }
          blog_categories {
            category
            slug
          }
          admin_users {
            username
          }
          ReadTime
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          adminImg {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 40)
              }
            }
          }
        }
      }
    }
  `);

  const [title, setTitle] = useState('')
  const { search }: any = useSearchFun();
  useEffect(() => {
    const name = JSON.parse(localStorage.getItem('name'));
    setTitle(name[1])
  }, [])

  return (
    <div className="col-lg-8">
      <div className="page_header pb-5">
        <div className="admin_page">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 p-0">
                <div className="d-flex admin_name">
                  <div>
                    <span className="search-post text-uppercase">
                      {"Search Results"}
                    </span>
                    <h1 className="pt-3">{title}</h1>
                    <div className="archive_count">
                      {`${search.length} Post`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        </div>
      </div>
      <>
        {/* {console.log(search)} */}
        {data.allStrapiPost.nodes.map((e) => {
          return search
            .slice(0, numberOfItems ?? 3)
            .map((val: any) => {
              return val === e.slug ? (
                <SwitchBlog
                  slug={e.slug}
                  image={e.image}
                  category={e.blog_categories}
                  title={e.Title}
                  blogPara={""}
                  date={e.date}
                  adminName={e.admin_users[0].username}
                  adminLink={e.admin_users[0].username
                    .toLowerCase()
                    .replace(" ", "-")}
                  readTime={e.ReadTime}
                />
              ) : null;
            });
        })}
        <div className={search.length > 10 ? "pt-5" : "d-none"}>
          {search.length >= numberOfItems && (
            <button
              className="cs-load-more"
              onClick={() =>
                setNumberOfItems((state) => state + 10)
              }
            >
              Load More
            </button>
          )}
        </div>
      </>
    </div>
  );
};
export default React.memo(Heading);
