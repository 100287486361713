import React from "react";
import ArticlePosts from "./ArticlePosts";
import ArticleForm from "./ArticleForm";
import FeaturedCategories from "./FeaturedCategories";
import Layout from "../components/Layout/layout";
import "./Blog.scss";
// import { GlobalStateContext } from "../components/Context/Context";
import Heading from "../components/Heading/Heading";

interface SearchBar {
  pageContext;
  props;
  title;
  path: string;
}

const SearchBar: React.FC<SearchBar> = (props) => {
  const { pageContext } = props;
  // const a = useContext(GlobalStateContext);
  // const searcher = a.searchQuery;

  // React.useEffect(() => {}, [a.searchQuery]);

  return (
    <Layout>
      <div className="review py-5">
        <div className="container">
          <div className="row">
            <Heading pageContext={pageContext} />
            <div className="col-lg-4 px-lg-5 mt-lg-0 mt-md-5">
              <div className="h-100">
                <div className="d-flex flex-column flex-sm-row flex-lg-column set-sticky">
                  <div>
                    <ArticleForm />
                  </div>
                  <div className="pt-lg-3 pt-sm-0 col-lg-12 col-sm-6  scrolling-effect">
                    <ArticlePosts />
                    <FeaturedCategories />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default SearchBar;